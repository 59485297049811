import "./Contact.css";
import { GoArrowLeft } from "react-icons/go";
import { AiOutlineClose } from "react-icons/ai";
import { FaPhoneAlt } from "react-icons/fa";
import emailjs from "emailjs-com";
import { useState } from "react";
import swal from "sweetalert";
import { useNavigate } from "react-router-dom";

export const Contact=()=>{

   const [templateParams, setTemplateParams] = useState({
    from_name:"",
    phone:"",
    requirement:""
 });

 const navigate = useNavigate();

 const service_id ="service_bom1pno";
  const user_id = "qZ5BjCJyHYMK-ycil";
   const template_id ="template_ruo0hgp";

 const onInputChange = (e) => {
   setTemplateParams({ ...templateParams, [e.target.name]: e.target.value });
 };

 const onSubmit = (e) => {
   e.preventDefault();
    emailjs
     .sendForm(service_id,template_id,e.target,user_id)
     .then((result) => {
       e.target.reset();
     })
     .catch((error) => {
       console.error(error.text);
     });

     setTimeout(() => {
       
       navigate('/thankyou');// Redirect to the thank you page
    }, 2000); // Simulating a 2-second delay

     setTemplateParams({
       from_name:"",
    phone:"",
    requirement:""
     });
 }

    return (
      <div id="contact-form-component-main-wrapper">
        <div id="contact-form-top-color-block"></div>
        <div id="contact-form-navigation-header">
          <GoArrowLeft />
          <AiOutlineClose />
        </div>
        <div id="contact-form-wrap">
          <div id="contact-form-phone-number-contact-wrap">
            <FaPhoneAlt />
            <div>+918466049965</div>
          </div>
          <div id="contact-form-project-title">Brindavan Lakes</div>
          <div id="contact-form-project-description">
            Ready to make this your dream home ? <br /> Get details on pricing
            and availability by filling out the form below.
          </div>
          <form id="contact-form-element-wrap" onSubmit={(e) => onSubmit(e)}>
            <div>
              <label
                htmlFor="contact-form-name-input"
                id="contact-form-name-label"
              >
                Name
              </label>
            </div>
            <input
              type="text"
              id="contact-form-name-input"
              name="from_name"
              onChange={(e) => onInputChange(e)}
              value={templateParams.from_name}
              required={true}
            />
            {/* 
            <div id="contact-form-email-label-wrap">
              <label
                htmlFor="contact-form-email-input"
                id="contact-form-email-label"
              >
                Email address
              </label>
            </div>
            <input type="text" id="contact-form-email-input" /> */}

            <div id="contact-form-phone-label-wrap">
              <label
                htmlFor="contact-form-phone-input"
                id="contact-form-phone-label"
              >
                Phone Number
              </label>
            </div>
            <input
              type="tel"
              id="contact-form-phone-input"
              name="phone"
              onChange={(e) => onInputChange(e)}
              value={templateParams.phone}
              required={true}
            />

            <div id="contact-form-count-label-wrap">
              <label
                htmlFor="contact-form-count-input"
                id="contact-form-count-label"
              >
                How many bedroom are you looking for?
              </label>
            </div>
            <input
              type="number"
              id="contact-form-count-input"
              name="requirement"
              onChange={(e) => onInputChange(e)}
              value={templateParams.requirement}
              required={true}
            />

            <button id="contact-form-submit-button">Submit</button>
          </form>
        </div>
      </div>
    );
}