import './App.css';
import { Routes, Route } from "react-router-dom";
import {Contact} from "./Components/Contact";
import {Thankyou} from "./Components/Thankyou";

function App() {
  return (
    <div className="App">
      <Routes>
        <Route path="/" element={<Contact />} />
        <Route path="/thankyou" element={<Thankyou />} />
      </Routes>
    </div>
  );
}

export default App;
